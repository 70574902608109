import styled, { keyframes, css, createGlobalStyle } from "styled-components"
import { normalize, rem } from "polished"

export const shake = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  5% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-5deg); }
  20% { transform: translate(-3px, 0px) rotate(5deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(5deg); }
  50% { transform: translate(-1px, 2px) rotate(-5deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-5deg); }
  80% { transform: translate(-1px, -1px) rotate(5deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`

interface BubbleProps {
  $speed?: any
  $delay?: any
  $animate?: boolean
  $type?: number
}

export const GlobalStyles = createGlobalStyle`
  ${normalize()};

  html {
/* 

    @media all and (min-width: 1600px) {
      font-size: 22px;
    }
*/
    @media all and (max-width: 1040px), (min-width: 1441px) and (max-height: 1040px) {
      font-size: 16px
    }

    /* @media all and (max-width: 480px) {
      font-size: 10px
    }  */
  }

  body {
    position: relative;
    min-height: 100vh;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    font-size: ${(props) => rem(props.theme.fontSize.base)};
    font-family: ${(props) => props.theme.font.base};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary} ;
    direction: ltr;
    overscroll-behavior: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    outline: none !important;
    transition: all ${(props) => props.theme.transition.base};
  }

  p {
    margin-top: 0;
  }

  h1 {
    margin-bottom: ${rem(10)};
    font-size: ${(props) => rem(props.theme.fontSize.h1)};
    font-size: 3vw;
    color:  ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.font.headings};

    @media all and (max-width: 767px) {
      font-size: 28px;
      margin-bottom: 22px;
    }

    @media all and (min-width: 2600px) {
      font-size: ${(props) => rem(props.theme.fontSize.h1)};
    }
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
`

export const Grid = styled.div`
  width: 100%;
  max-width: ${rem(1680)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  margin-left: auto;
  margin-right: auto;

  @media all and (min-width: 2080px) {
    max-width: ${rem(2080)};
  }
`

export const Box = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;

  @media all and (min-width: 768px) {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const Bubble = styled.div<BubbleProps>`
  position: absolute;
  transform: translate(0, 0) rotate(0deg);
  left: 0;
  top: 0;
  will-change: transform;

  ${(props) =>
    props.$animate &&
    css`
      animation: ${props.$speed ?? 20}s ${shake} infinite linear ${props.$delay ?? 0}s;
    `}
`

export const BubbleTop = styled(Bubble)`
  display: none;

  @media all and (min-width: 1820px) {
    display: block;
  }

  @media all and (max-width: 767px) {
    display: block;
    top: -50px !important;
    left: -120px !important;

    svg {
      width: 180px;
      height: 180px;
    }
  }
`

export const BubbleBottom = styled(Bubble)`
  @media all and (max-width: 767px) {
    left: -90px !important;
    bottom: -120px !important;

    svg {
      width: 220px;
      height: 220px;
    }
  }
`
