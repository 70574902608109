import styled from "styled-components"
import { rem } from "polished"

export const Lead = styled.div`
  width: ${rem(840)};
  max-width: 100%;
  margin: 0 auto ${rem(90)};
  font-size: ${(props) => rem(props.theme.fontSize.lead)};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 1.12;
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.secondary};
  }

  @media all and (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 75px;
    width: 480px;
  }

  @media all and (min-width: 1820px) {
    width: ${rem(1200)};
  }
`
