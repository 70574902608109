import React from "react"

import { Grid } from "../../core/GlobalStyles"

import * as S from "./styled"

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <S.Wrapper>
      <Grid>
        <S.Copy>
          &copy; {currentYear}. Built by{" "}
          <a href="https://stake.fish/" target="_blank" rel="noopener">
            stakefish
          </a>{" "}
          and{" "}
          <a href="https://www.f2pool.com/" target="_blank" rel="noopener">
            f2pool
          </a>
        </S.Copy>
      </Grid>
    </S.Wrapper>
  )
}

export default Footer
