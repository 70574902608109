import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.header`
  padding: ${rem(60)} 0;
  width: 100%;
  text-align: center;
  line-height: 0;
  font-size: 0;

  img {
    width: ${rem(422)};
    pointer-events: none;
    max-width: 100%;
  }

  @media all and (max-width: 767px) {
    padding: 32px 0 24px;
  }
`

export const BubbleTop = styled.div`
  @media all and (max-width: 767px) {
    top: 30px !important;
    left: auto !important;
    right: -150px;

    svg {
      width: 180px;
      height: 180px;
    }
  }
`
