import React from "react"

import Layout from "./Layout"

import Footer from "../containers/Footer"
import Main from "../containers/Main"
import Projects from "../containers/Projects"
import Header from "../containers/Header"

import Oval from "../assets/shapes"
import { BubbleTop, BubbleBottom, Box } from "../core/GlobalStyles"
import {} from "../containers/Header/styled"
import { Lead } from "../components/Lead"

const App: React.FC = () => {
  return (
    <Layout>
      <BubbleTop $animate $delay={4} $speed={25} style={{ left: "-350px", top: "-400px" }}>
        <Oval />
      </BubbleTop>

      <Box>
        <Header />

        <Main>
          <Lead>
            Brought to you by{" "}
            <a href="https://stake.fish/" target="_blank" rel="noopener">
              stakefish
            </a>{" "}
            &{" "}
            <a href="https://www.f2pool.com/" target="_blank" rel="noopener">
              f2pool
            </a>
            ! We help run blockchains and care about crypto memes. Enjoy our open source web application. Feel free to
            fork and play around with it!
          </Lead>

          <Projects />
        </Main>

        <Footer />

        <BubbleBottom $animate $delay={3} $speed={30} style={{ left: "-100px", top: "auto", bottom: "-180px" }}>
          <Oval $type={7} />
        </BubbleBottom>
      </Box>
    </Layout>
  )
}

export default App
