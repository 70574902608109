import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.div``

export const Item = styled.div`
  text-align: center;
  width: calc(100% / 5);
  padding: 0 ${rem(22)};
  margin-bottom: ${rem(40)};

  @media all and (max-width: 1200px) {
    width: calc(100% / 2);
    margin-bottom: ${rem(80)};
  }

  @media all and (max-width: 767px) {
    padding: 0;
    width: 100%;
    margin-bottom: 85px;
  }

  @media all and (min-width: 1820px) {
    padding: 0 ${rem(40)};
  }
`

export const PreviewImg = styled.img`
  max-width: 100%;
  border-radius: ${rem(16)};
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.2, 1.64, 0.41, 0.2) 0s;
`

export const Logo = styled.img`
  max-width: 100%;
  will-change: transform;
  transition: transform 0.3s ease;

  @media all and (max-width: 767px) {
    max-height: 100%;
    object-fit: contain;
  }
`

export const Desc = styled.div`
  margin-bottom: ${rem(35)};
  font-weight: ${(props) => props.theme.fontWeight.medium};

  @media all and (max-width: 767px) {
    margin-bottom: 20px;
  }
`

export const Links = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 0 ${rem(4)};
  }
`

export const Header = styled.div`
  margin: 0 auto ${rem(45)};
  width: ${rem(180)};
  height: ${rem(180)};
  position: relative;

  a {
    font-size: 0;
    line-height: 0;
    display: block;
    height: 100%;
    border-radius: ${rem(20)};
    position: relative;
    z-index: 2;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);

    &:hover {
      ${PreviewImg} {
        transition: transform 0.3s ease;
        transform: scale(1.1) translate3d(0, -5px, 0);
      }
    }
  }

  @media all and (max-width: 767px) {
    width: 120px;
    height: 120px;
    margin-bottom: 35px;

    svg {
      width: 120%;
      height: 130%;
    }
  }
`

export const Title = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${rem(95)};
  margin-bottom: ${rem(30)};

  &:hover {
    ${Logo} {
      transform: scale(1.01);
    }
  }

  @media all and (max-width: 767px) {
    height: 54px;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
  }
`

export const List = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`
