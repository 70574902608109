import styled, { css } from "styled-components"
import { rem } from "polished"

export enum ButtonColor {
  Primary,
  Secondary
}

export interface ButtonProps {
  $color?: ButtonColor
  $block?: boolean
}

export const Button = styled.button<ButtonProps>`
  padding: ${rem(14)} ${rem(20)} ${rem(12)};
  border-radius: ${rem(10)};
  position: relative;
  letter-spacing: 0;
  line-height: 1;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${(props) => rem(props.theme.fontSize.lead)};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.dark};
  box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.13), 0 2px 0 0 ${(props) => props.theme.colors.odd};
  transition: background-color ${(props) => props.theme.transition.base},
    box-shadow ${(props) => props.theme.transition.base};
  appearance: none;
  cursor: pointer;
  outline: none !important;
  min-width: ${rem(60)};
  font-size: ${rem(15)};
  white-space: nowrap;

  svg {
    height: ${rem(20)};
    margin: ${rem(-5)} ${rem(8)} ${rem(-3)} ${rem(-5)};
  }

  &:hover {
    background-color: #eaeaea;
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.13), 0 2px 0 0 #ababab;
  }

  &:active {
    background-color: #e1d4ee;
    box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.13), 0 -2px 0 0 #400081;
  }

  @media all and (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 14px;
    min-width: 40px;
  }

  ${(props) =>
    props.$color === ButtonColor.Secondary &&
    css`
      color: red;

      &:hover {
        color: red;
      }
    `}
`
