import styled from "styled-components"
import { rem } from "polished"

export const Wrapper = styled.footer`
  padding: ${rem(55)} 0;
  width: 100%;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-align: center;

  a {
    color: inherit;
    color: ${(props) => props.theme.colors.secondary};
  }
`

export const Copy = styled.div`
  @media all and (max-width: 767px) {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
`
