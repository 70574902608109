import React from "react"

import { Grid, Bubble } from "../../core/GlobalStyles"
import Oval from "../../assets/shapes"

import * as S from "./styled"

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <Bubble as={S.BubbleTop} $animate $delay={4} $speed={30} style={{ left: "90%", top: "-5%" }}>
        <Oval $type={6} />
      </Bubble>

      <Grid>
        <img src="/images/logo.svg" alt="playground.fish" width="422" />
      </Grid>
    </S.Wrapper>
  )
}

export default Header
