import React, { ReactNode } from "react"
import { Grid } from "../../core/GlobalStyles"

import * as S from "./styled"

interface Props {
  children: JSX.Element | JSX.Element[] | ReactNode
}

const Main: React.FC<Props> = ({ children }: Props) => {
  return (
    <S.Wrapper>
      <Grid>{children}</Grid>
    </S.Wrapper>
  )
}

export default Main
