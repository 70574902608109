import React from "react"

import Oval from "../../assets/shapes"

import { Grid, Bubble } from "../../core/GlobalStyles"
import { Button, ButtonColor } from "../../components/Button"

import { IconYoutube, IconGithub } from "../../icons"

import * as S from "./styled"

const data = [
  {
    previewImg: "taproot-img.png",
    logo: "taproot-name.svg",
    logoWidth: "235px",
    title: "Taproot Mask",
    description: "Upload your photo, choose your mask, and download or share with the Twitter button.",
    links: {
      website: "https://taproot.fish/",
      youtube: "https://www.youtube.com/watch?v=KbF6daItSrw",
      github: "https://github.com/stakefish/taproot-v2"
    }
  },
  {
    previewImg: "optimism-img.png",
    logo: "optimism-name.svg",
    logoWidth: "253px",
    title: "Optimism Mask",
    description:
      "Do you part to support Optimistic Rollups for Ethereum's better scaling tomorrow! Upload your photo, choose your mask, and download or share with the Twitter button!",
    links: {
      website: "https://imoptimistic.xyz/",
      youtube: "https://www.youtube.com/watch?v=97DU_YgNPgE",
      github: "https://github.com/stakefish/i-m-optimistic"
    }
  },
  {
    previewImg: "tamagotchi-img.png",
    logo: "tamagotchi-name.svg",
    logoWidth: "280px",
    title: "TAMAGOTCHI ZONE",
    description:
      "GoZ stands for Game of Zones. GoZ is a technical competition made up of a series of adversarial testnet challenges designed to prepare the Cosmos ecosystem for the upcoming launch of IBC.",
    links: {
      website: "https://stake.fish/goz/",
      youtube: "https://www.youtube.com/watch?v=ZRIHw-qxots"
      // github: "https://github.com/stakefish/i-m-optimistic" // TODO
    }
  },
  {
    previewImg: "solana-img.png",
    logo: "solana-name.svg",
    logoWidth: "271px",
    title: "Solana Pitviper Mask",
    description:
      "SolanaSZN is starting. Get your own SolanaSZN shades here! Upload your photo, choose your shades, and download or share with the Twitter button!",
    links: {
      website: "https://mask.solana.fish/",
      youtube: "https://www.youtube.com/watch?v=VtMzdiqLlHY",
      github: "https://github.com/stakefish/solana-pitviper-mask"
    }
  },
  {
    previewImg: "laser-img.png",
    logo: "laser-name.svg",
    logoWidth: "260px",
    title: "Crypto Laser Eyes Mask",
    description: "Are you a true brrrrliever? Choose your laser eyes and tell the world!",
    links: {
      website: "https://cryptolasereyes.com/",
      youtube: "https://www.youtube.com/watch?v=IRNPwoU4Slw",
      github: "https://github.com/stakefish/crypto-laser-eyes"
    }
  }
]

const Projects: React.FC = () => {
  return (
    <S.Wrapper>
      <Grid>
        <S.List>
          {data.map((item, i) => (
            <S.Item key={i}>
              <S.Header>
                <Bubble $animate $delay={i + 1}>
                  <Oval $type={i + 1} />
                </Bubble>
                <a href={item.links.website} target="_blank" rel="noopener">
                  <S.PreviewImg src={`/images/projects/${item.previewImg}`} alt={item.title} />
                </a>
              </S.Header>
              <S.Title href={item.links.website} target="_blank" rel="noopener">
                <S.Logo src={`/images/projects/${item.logo}`} width={item.logoWidth} alt={item.title} />
              </S.Title>
              <S.Desc>{item.description}</S.Desc>
              <S.Links>
                {item.links.youtube && (
                  <Button
                    $color={ButtonColor.Secondary}
                    as="a"
                    href={item.links.youtube}
                    target="_blank"
                    rel="noopener"
                  >
                    <IconYoutube />
                    <span>Video</span>
                  </Button>
                )}
                {item.links.github && (
                  <Button as="a" href={item.links.github} rel="noopener">
                    <IconGithub />
                    <span>GitHub</span>
                  </Button>
                )}
              </S.Links>
            </S.Item>
          ))}
        </S.List>
      </Grid>
    </S.Wrapper>
  )
}

export default Projects
