import React, { ReactNode } from "react"
import { rem } from "polished"

interface Props {
  $strokeWidth?: number | string
  $color?: string
  $type?: number | string
  $rotate?: number | string
  as?: ReactNode
}

const Oval: React.FC<Props> = ({ $strokeWidth = 3, $color = "#FFDE1C", $type, $rotate = 0 }: Props) => {
  if ($type === 1) {
    return (
      <svg
        width={rem(234)}
        height={rem(209)}
        style={{ transform: `translate(${rem(-42)}, ${rem(-41)})` }}
        viewBox="0 0 234 209"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-849.000000, -477.000000)" fill={$color} fillRule="nonzero">
            <path d="M1075.99478,513.99347 C1091.9419,542.801171 1076.33773,588.413364 1052.67426,622.708246 C1029.01079,657.174603 997.116547,679.9807 957.334484,684.610509 C917.55242,689.240318 869.882534,675.69384 854.96426,646.371715 C840.045987,617.049591 857.879325,572.294769 881.885743,538.857259 C905.720686,505.591223 935.557234,483.642499 974.138976,478.669741 C1012.72072,473.525508 1060.21913,485.357243 1075.99478,513.99347 Z"></path>
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 2) {
    return (
      <svg
        width={rem(203)}
        height={rem(217)}
        style={{ transform: `translate(${rem(-34)}, ${rem(-16)})` }}
        viewBox="0 0 203 217"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1137.000000, -477.000000)" fill={$color} fillRule="nonzero">
            <path d="M1335.69413,539.120684 C1347.82385,573.726069 1326.41846,614.039558 1303.58604,643.650351 C1280.75362,673.261144 1256.67255,692.169241 1230.80771,693.239511 C1204.94286,694.131402 1177.4726,677.007088 1158.74288,649.715212 C1140.01317,622.423337 1130.02398,584.9639 1143.75911,549.288246 C1157.49424,513.612592 1194.95367,479.72072 1236.51581,477.401802 C1278.07795,475.082885 1323.56441,504.336921 1335.69413,539.120684 Z"></path>
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 3) {
    return (
      <svg
        width={rem(216)}
        height={rem(203)}
        style={{ transform: `translate(${rem(9)}, ${rem(-46)})` }}
        viewBox="0 0 216 203"
      >
        <g fill="none" fill-rule="evenodd">
          <g fill={$color} fill-rule="nonzero">
            <g>
              <path
                d="M247.425 58.99C267.28 92.083 276.786 135.746 261.75 161c-15.036 25.254-36.744 41.149-95.651 41.628-58.907.48-88.415-21.58-104.572-43.578C45.371 137.052 57.05 93.057 79.241 58.6 101.237 24.338 133.551-.385 165.085.005c31.729.389 62.68 25.89 82.34 58.985z"
                transform="translate(-1470.000000, -566.000000) translate(1416.000000, 566.000000)"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 4) {
    return (
      <svg
        width={rem(180)}
        height={rem(220)}
        style={{ transform: `translate(${rem(26)}, ${rem(-46)})` }}
        viewBox="0 0 180 220"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-876.000000, -731.000000)" fill={$color} fillRule="nonzero">
            <path d="M1049.79083,808.853167 C1063.23732,851.791861 1051.08688,901.859998 1016.74164,928.433379 C982.396404,955.006759 926.018373,958.085383 898.315374,934.914691 C870.774382,911.744 871.908423,862.161961 886.488948,817.602939 C901.231479,772.881884 929.420495,733.345879 963.117709,731.563518 C996.976929,729.781157 1036.18234,765.75244 1049.79083,808.853167 Z"></path>{" "}
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 5) {
    return (
      <svg
        width={rem(234)}
        height={rem(217)}
        style={{ transform: `translate(${rem(-38)}, ${rem(-24)})` }}
        viewBox="0 0 234 217"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1121.000000, -738.000000)" fill={$color} fillRule="nonzero">
            <path d="M1322.34608,777.438675 C1346.97462,805.922118 1364.32168,852.823425 1348.25959,890.301639 C1332.1975,927.779853 1282.94042,956.049134 1236.89575,954.121683 C1190.85109,952.194232 1148.01885,920.498371 1130.88595,882.377674 C1113.75305,844.256976 1122.53366,799.711442 1143.94978,773.155451 C1165.3659,746.385298 1199.41754,737.604688 1232.61253,738.247171 C1265.80752,738.889655 1297.71754,748.955232 1322.34608,777.438675 Z"></path>
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 6) {
    return (
      <svg width={rem(407)} height={rem(341)} viewBox="0 0 407 341">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-850.000000, -96.000000)" fill={$color} fillRule="nonzero">
            <path d="M1216.43247,207.75245 C1254.04671,270.447025 1270.64123,347.524473 1241.13986,388.460342 C1212.00726,429.02742 1136.77877,433.452919 1057.86261,436.034461 C978.577682,438.616002 894.867548,438.984793 864.259878,397.311341 C833.652208,355.637888 855.778234,272.290983 897.817685,207.014867 C939.488368,142.107542 1000.70371,95.2710071 1060.44398,96.0085903 C1120.55302,96.7461736 1179.18699,145.057875 1216.43247,207.75245 Z"></path>
          </g>
        </g>
      </svg>
    )
  }

  if ($type === 7) {
    return (
      <svg width={rem(395)} height={rem(310)} viewBox="0 0 395 310">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-850.000000, -1019.000000)" fill={$color} fillRule="nonzero">
            <path d="M1237.79487,1090.42994 C1257.03931,1130.52253 1233.94598,1188.5766 1203.15487,1233.15956 C1172.04302,1277.42178 1133.55414,1308.85437 1071.97192,1322.00474 C1010.06896,1335.47585 925.072672,1330.98548 882.414157,1279.66697 C840.076382,1228.34845 839.755642,1130.20179 882.093416,1076.95883 C924.751931,1023.39514 1009.74822,1014.73514 1083.19784,1021.47069 C1156.64747,1028.20625 1218.55042,1050.6581 1237.79487,1090.42994 Z"></path>
          </g>
        </g>
      </svg>
    )
  }

  return (
    <svg
      width={rem(767)}
      height={rem(863)}
      viewBox="0 0 787 863"
      version="1.1"
      style={{ transform: `rotate(${$rotate}deg)` }}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={$color} fillRule="nonzero">
          <path
            d="M806.79 697.124c51.201 173.188-43.01 377.06-191.835 478.654-148.826 102.277-352.95 102.277-466.958 11.592-114.691-90.686-139.95-272.056-88.75-445.926C110.45 568.255 237.43 402.567 398.544 391.658 560.34 380.066 756.271 523.254 806.79 697.124z"
            transform="translate(-34.000000, -391.000000)"
          />
        </g>
      </g>
    </svg>
  )
}

export default Oval
